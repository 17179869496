export const APP_NAME = 'SysBox'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'
export const TIPOS_CONTAS = [
    { label: 'Carteira', value: 'carteira' },
    { label: 'Conta Bancária', value: 'conta' },
]
export const PFIN_TIPO = [
    { label: 'Pagar', value: 'P' },
    { label: 'Receber', value: 'R' },
]
export const PFIN_STATUS = [
    { label: 'Em Aberto', value: 'P', class: 'bg-yellow-100 text-yellow-800 font-semibold rounded-full p-2 text-xs' },
    { label: 'Quitado', value: 'B', class: 'bg-green-600 text-white font-semibold rounded-full p-2 text-xs' },
    // { label: 'Cancelado', value: 'C', class: '' },
]
export const PFIN_PERIODO = [
    { label: 'Diário', value: '1' },
    { label: 'Semanal', value: '7' },
    { label: 'Quinzenal', value: '15' },
    { label: 'Mensal', value: '30' },
    { label: 'Bimestral', value: '60' },
    { label: 'Trimestral', value: '90' },
    { label: 'Semestral', value: '180' },
    { label: 'Anual', value: '360' },
]

export const PFIN_TIPO_EXCLUSAO = [
    { label: 'Somente Este', value: 'este' },
    { label: 'Desta data em diante', value: 'esteEmDiante' },
    { label: 'Todos os lançamentos (inclusive retroativos)', value: 'todos' },
]

export const PROJ_SITUACOES = [
    { value: 'breve', label: 'Inicio em breve' },
    { value: 'andamento', label: 'Em andamento' },
    { value: 'concluido', label: 'Concluído' },
    { value: 'cancelado', label: 'Cancelado' },
]

export const PROJ_TAREFA_TIPO_RESPONSAVEL = [
    { value: 'cliente', label: 'Cliente' },
    { value: 'empresa', label: 'Empresa' },
    { value: 'ninguem', label: 'Ninguem (usar para concluído)' },

]
export const PROD_TIPOS = [
    { value: 'P', label: 'Produto' },
    { value: 'S', label: 'Serviço' },
]

export const PROD_TPPRECOS = [
    { value: 'U', label: 'Único' },
    { value: 'R', label: 'Recorrente' },
]
